.hover-opacity:hover {
    opacity: .6;
}

.user-select-none {
    user-select: none;
}

.pointer-event-none {
    pointer-events: none;
}

.reset-a, .reset-a:hover {
    text-decoration: none;
    color: inherit;
}

.reset-ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.reset-btn {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    overflow: visible;
    text-transform: none;
    display: inline-block;
    outline: 0;
    color: inherit;

    &::-moz-focus-inner {
        border-style: none;
        padding: 0;
    }
    &::-moz-focusring {
        outline: 1px dotted ButtonText;
    }
}

.reset-legend {
    margin-bottom: 0;
    font-size: 1rem;
}

.mdi-outline {
    &::before {
        color: $black;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $black;
    }
}

.border-dashed {
    border-style: dashed !important;
}