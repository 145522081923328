.custom-modal {
    background-color: rgba($dark, .7);
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.15s ease-in-out;
    z-index: $zindex-modal;

    & .custom-modal-container {
        background-color: $white;
        margin-top: auto;
        padding-top: $spacer;
        padding-bottom: $spacer;
        width: 100%;
    }
}

@include media-breakpoint-up(sm) {
    .custom-modal {
        display: flex;
        align-items: center;
        justify-content: center;

        & .custom-modal-container {
            border-radius: $border-radius;
            border: $border-width solid $border-color;
            box-shadow: $box-shadow;
            margin-top: 0;

            &-sm {
                max-width: map-get($container-max-widths, sm);
            }

            &-md {
                max-width: map-get($container-max-widths, md);
            }

            &-lg {
                max-width: map-get($container-max-widths, lg);
            }
        }
    }

}
