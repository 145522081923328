@mixin button-circle-variant($size, $color) {
    @include circle($size);
    outline: none;
    box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
    border: 0;
    transition: background-color 0.15s ease-in-out, box-shadow 0.25s ease-in-out;

    &:hover {
        background-color: $color;
        box-shadow: 0 0 0 5px rgba($color, .6), 0 0 0 0 rgba($color, .4);
    }
    &:active {
        background-color: $color;
        transform: scale(0.9);
    }
    &:focus {
        transition: background-color 0.15s ease-in-out, box-shadow 0.35s ease-in-out;
        box-shadow: 0 0 0 20px transparent, 0 0 0 50px transparent;
    }
}