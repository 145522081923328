.text-decoration-underline {
    text-decoration: underline !important;
}

h1, .h1,
h2, .h2,
h3, .h3 {
    font-weight: 500;
}

.font-sm {
    font-size: $font-size-sm;
}

.mdi.outline {
    color: $body-color;
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $body-color;
}