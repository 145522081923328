@use "sass:math";

.team-profile {
    &-nav {
        position: relative;

        &-list {
            padding-left: math.div($spacer, 2);
            padding-right: math.div($spacer, 2);
            border-bottom: $border-width solid $border-color;
            box-shadow: $box-shadow-sm;
            display: flex;
            list-style-type: none;
            overflow-x: auto;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */

            &::-webkit-scrollbar {
                display: none;
            }

            &::after {
                content: '';
                width: 40px;
                height: 100%;
                background: linear-gradient(to left, $white, transparent);
                position: absolute;
                right: 0;
                top:0;
                bottom:0;
            }
        }
        &-item {
            border-bottom: 2px solid transparent;
            border-top: 2px solid transparent;
            white-space: nowrap;

            &.active {
                border-bottom: 2px solid $primary;
            }
        }
        &-btn {
            padding-left: math.div($spacer, 2);
            padding-right: math.div($spacer, 2);

            &:focus {
                outline: 0;
                box-shadow: none;
            }
        }
    }
}