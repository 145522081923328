.item {
    padding: map-get($spacers, 2);
    box-shadow: $box-shadow-sm;
    border: $border-color solid $border-width;
    display: flex;
    background-color: $white;
    border-radius: $border-radius;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    flex-basis: 55%;
    margin-right: map-get($spacers, 2);
    text-align: center;

    @include media-breakpoint-up(sm) {
        flex-basis: 40%;
    }

    @include media-breakpoint-up(md) {
        align-items: flex-start;
        flex-direction: row;
        flex-basis: 40%;
        flex-basis: auto;
        margin-right: 0;
        text-align: left;
    }

    &-list {
        // Mobile exclusive
        @include media-breakpoint-down(sm) {
            display: flex;
            overflow-x: scroll;

            &-container{
                position: relative;

                &::after {
                    content: '';
                    width: 20px; // gradient width
                    background: linear-gradient(to left, $light, transparent);
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    display: block;
                }
            }

            &::after { // temp fix for gradient (doesn't show when at end of list)
                content: '';
                display: block;
                width: 20px; // width is same as gradient width
                height: 1px;
                flex-shrink: 0;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        align-items: center;
    }

    &-image {
        max-width: 80px;
        border: 1px solid $border-color;
        border-radius: $border-radius;

        @include media-breakpoint-up(sm) {
            max-width: 50px;
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-width: 0; // used to make sure the title overflows

        @include media-breakpoint-up(md) {
            flex-direction: row;
            align-self: center;
            display: flex;
            align-items: center;
        }
    }

    &-price {
        margin-top: auto;
        padding: map-get($spacers, 1);
        color: $text-muted;
        white-space: nowrap;

        @include media-breakpoint-up(md) {
            padding: 0;
        }
    }

    &-number {
        display: inline-block;
        width: 1.75rem;
        text-align: center;
        margin: 0 map-get($spacers, 1);

        &-btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 1.75rem;
            width: 1.75rem;

            &:disabled {
                background-color: $gray-300;
                border-color: $gray-300;
                color: #000;
            }
        }
    }
}