// this is temporary, since it might break if there is an update.
#chat-container {
    & .spartez-support-chat-container {
        & .top-header {
            background-color: $primary;

            @include media-breakpoint-up(sm) {
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
            }
        }
        & .op.back-from-chat,
        & .op.close {
            background-image: none;
            font-size: 1.5rem;

            &::before {
                @include mdiIcon("\F141");
            }
        }
        & .op.back-from-chat::before {
            content: "\F141";
        }
        & .op.close::before {
            content: "\F156";
        }
        & .op.back-from-chat,
        & .op.close,
        & .issue-link a {
            border-radius: 1000px;
            &:hover {
                background-color: darken($primary, 7.5%);
            }
        }
        & .top-shadow {
            display: none;
        }
        & .chat-message.my {
            background-color: $primary;

            & p {
                color: $white;
            }
        }
        & .chat-conversation:hover {
            border-color: $primary;
        }
        & .new-conversation {
            font-family: $font-family-sans-serif;
            font-weight: normal;
            background-image: none;
            background-color: $primary;
            border-radius: $btn-border-radius;
            box-shadow: none;

            &:hover {
                box-shadow: none;
                background-color: darken($primary, 7.5%);
            }

            &::before {
                @include mdiIcon("\F181");
            }
        }
        & .message-entry {
            & .add-content {
                &:hover::before {
                    color: $primary;
                }

                &.add-attachment {
                    background-image: none;

                    &::before {
                        @include mdiIcon("\F066");
                    }
                }

                &.add-smiley {
                    background-image: none;

                    &::before {
                        @include fontAwesomeIcon("\f118");
                    }
                }
            }
            & .send-message {
                background-color: $primary;

                &:disabled {
                    opacity: 0.75;
                    pointer-events: none;

                    &:hover {
                        background-color: $primary;
                    }
                }

                &:hover {
                    background-color: darken($primary, 7.5%);
                }
            }
        }
    }
}
.help-dropdown {
    #chat-container &-menu.spartez-support-chat-container {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        box-shadow: $box-shadow-lg;
    }
    &-toggle {
        position: fixed;
        bottom: 1vw;
        right: 1vw;
        z-index: $zindex-popover;
        width: 50px;
        height: 50px;
        border-radius: 50%;

    }
}