$confirmed-duration: .5s;
$confirmed-delay: 2s;

.participant {

    // this resets some registration-card's styling
    &.is-disabled {
        &:hover {
            border: 1px solid $border-color;
            cursor: default
        }

        &:hover .registration-card-icon {
            opacity: 0;
        }
    }

    &.in-cart {

    }

    &-hcrNumber { //default style (.text-muted.small), changed when .is-new
        color: $text-muted;
        @include font-size($small-font-size);
        font-weight: $font-weight-normal;

        i {
            display: none; // just in case we always render the icon
        }
    }

    &-linkToHcr {
        color: $info;

        &:hover {
            text-decoration: underline;
        }
    }
    // card no longer used because we have registration-card
    // &-card {
    //     transition: all $transition-duration ease;
    //     border-radius: $border-radius;

    //     &.is-new {
    //         background-color: lighten($success, 50%);
    //         animation-name: confirmed-bg;
    //         animation-duration: $confirmed-duration;
    //         animation-delay: $confirmed-delay;
    //         animation-iteration-count: 1;
    //         animation-fill-mode: forwards;

    //         .participant-hcrNumber {
    //             color: $success;
    //             animation-name: confirmed-text;
    //             animation-duration: $confirmed-duration;
    //             animation-delay: $confirmed-delay;
    //             animation-iteration-count: 1;
    //             animation-fill-mode: forwards;

    //             i {
    //                 display: inline-block;
    //                 animation-name: confirmed-icon;
    //                 animation-duration: $confirmed-duration;
    //                 animation-delay: $confirmed-delay;
    //                 animation-iteration-count: 1;
    //                 animation-fill-mode: forwards;
    //             }
    //         }
    //     }
    // }


    // link icon
    // &-icon-container {
    //     display: block;
    //     width: 2rem;
    //     height: 2rem;
    //     text-align: center;

    //     & i {
    //         font-size: 2rem;
    //         line-height: 1;
    //     }
    // }

    // &-linkMember-arrow {
    //     align-self: center;
    //     border-radius: 50%;
    //     height: 1.5em;
    //     width: 1.5em;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    // }
}

// @keyframes confirmed-bg {
//     0% {
//         background-color: lighten($success, 50%);
//     }

//     100% {
//         background-color: $white;
//     }
// }
// @keyframes confirmed-text {
//     0% {
//         color: $success;
//     }

//     100% {
//         color: $text-muted;
//     }
// }
// @keyframes confirmed-icon {
//     0% {
//         opacity: 1;
//     }

//     100% {
//         opacity: 0;
//     }
// }