.schedule {
    &-tab-content {
        min-height: 33vh;
    }
    &-nav {
        position: relative;
        background-color: $light;
        padding: map-get($spacers, 1);
        overflow:hidden;
        border-radius: $border-radius-lg;
        justify-content: space-between;

        & .nav-item {
            text-align: center;
            cursor: pointer;

            &.active {
                background-color: $white;
                box-shadow: $box-shadow;
                border-radius: $border-radius;
            }

            &.active .nav-link {
                color: $primary;
                position: relative;
                z-index: 10;
            }
        }

        & .nav-link {
            text-transform: uppercase;
            font-size: 0.9rem;
            color: $dark;
            padding-top: map-get($spacers, 1);
            padding-bottom: map-get($spacers, 1);
            transition: color 0.15s ease-in-out;
        }

    }
    &-datetime-container {
        position: relative;
        margin-bottom: map-get($spacers, 2);

        & .btn-today {
            margin-left: auto;
        }
    }
    &-filters-datetime {
        z-index: $zindex-popover;

        & .rdtPicker {
            border: $border-width solid $border-color;
            border-radius: $border-radius;
            box-shadow: $box-shadow-sm;
            background-color: $white;

            & .rdtToday {
                color: $primary;

                &::before {
                    display: none;
                }
            }

            & .rdtSwitch,
            & .rdtDay {
                border-radius: $border-radius;
                &:hover {
                    background-color: darken($light, 5%);
                }
            }
            & .rdtPrev,
            & .rdtNext {
                border-radius: 1000px;

                &:hover {
                    background-color: darken($light, 5%);
                }
                &::before {
                    font-size: 12px;
                    font-family: 'themify';
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-feature-settings: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
                & > span {
                    display: none;
                }
            }
            & .rdtPrev::before {
                content: "\e64a";
            }
            & .rdtNext::before {
                content: "\e649";
            }
        }
    }
}

/* phone */
@include media-breakpoint-down(sm) {
    .schedule {
        &-datetime-container {
            & .btn-today {
                order: 1;
            }

            & .btn-prev {
                order: 2;
            }

            & .btn-next {
                order: 3;
            }
        }
    }
}
