.register-block {
    &-title {
        font-size: 1.8rem;
    }
}

/* DESKTOP ONLY*/
@include media-breakpoint-up(lg) {
    .register-block {
        &-title {
            font-size: 2rem;
        }
    }
}