.registration-forms-list {
    &-item {
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        box-shadow: $box-shadow-sm;
        overflow: hidden;
        position: relative;
        margin-bottom: map-get($spacers, 3);
        transition: box-shadow 0.15s ease-in-out;

        &-icon {
            background-color: $dark;
            color: $white;
            min-height: 90px;
            width: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            align-self: stretch;

            & svg {
                max-width: 33%;
            }
        }

        &:hover {
            box-shadow: $box-shadow;
        }
    }
}