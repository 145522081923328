.post-publication {
    &-form {
        margin-bottom: map-get($spacers, 4);
    }
    &-preview {
        border: $border-color solid $border-width;
        border-radius: $border-radius;
        flex: 1 1 auto;
        padding: map-get($spacers, 2);
        margin-left: map-get($spacers, 3);
        position: relative;
        color: $text-muted;
        background-color: $white;
        display: flex;
        box-shadow: $box-shadow-sm;

        &:hover,
        &:active,
        &:focus {
            &,
            &::before {
                background-color: $light;
            }
        }

        &::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            background-color: $white;
            border-left: $border-color solid $border-width;
            border-bottom: $border-color solid $border-width;
            height: 12px;
            width: 12px;
            left: 0;
            top: 0;
            transform-origin: center;
            transform: translate(-50%, 100%) rotate(45deg);
            position: absolute;
        }
    }
}
.post-publication {
    &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &-container {
            background-color: $white;
            position: absolute;
            width: 100px;
            height: 100px;
            bottom: 0;
            margin-left: map-get($spacers, 2);
            margin-bottom: map-get($spacers, 2);
            box-shadow: $box-shadow-sm;
            border: $border-color solid $border-width;
            border-radius: $border-radius;
        }
        &-delete {
            box-shadow: none;
            border: 0;
            position: absolute;
            top: 0;
            right: 0;
            margin-top: map-get($spacers, 1);
            margin-right: map-get($spacers, 1);
        }
    }
    &-img-input {
        position:absolute;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
    }
    &-modal {
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: map-get($spacers, 2);
            margin-bottom: map-get($spacers, 3);
            border-bottom: $border-width solid $border-color;
        }
        &-title {
            margin-bottom: 0;
            font-weight: 500;
        }
        &-btn {
            flex-basis: 20%;
        }
    }
    &-textarea {
        resize: none;
        border: 0;
        width: 100%;
        position: absolute;
        background-color: transparent;
        height: 100%;
        color: rgba(0,0,0,0);
        caret-color: $black;
        resize: none;

        &::placeholder {
            color: $input-placeholder-color;
            // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
            opacity: 1;
        }

        &,
        &-overlay {
            white-space: pre-wrap;
            -webkit-writing-mode: horizontal-tb;
            line-height: 1;
            letter-spacing: normal;
            word-spacing: normal;
            padding: $input-padding-x;
            overflow-wrap: break-word;
            word-wrap: break-word;
            appearance: textarea;
            text-rendering: auto;
            text-transform: none;
            text-indent: 0px;
            text-shadow: none;
            text-align: start;
            -webkit-rtl-ordering: logical;
            hyphens: auto;
            cursor: text;
            font-size: 1rem;
            font: 400 1rem $font-family-sans-serif;
            -moz-appearance: textfield-multiline;
            -webkit-appearance: textarea;
            overflow-y: hidden;
        }
        &-overlay {
            color: $input-color;
        }
        &:focus,
        &:active {
            outline: none;
        }
        &-container {
            border-radius: $border-radius;
            background-color: $light;
            border: $input-border-width solid $input-border-color;
            height: 33vh;
            position: relative;
            margin-bottom: map-get($spacers, 2);
            border-bottom: $border-width solid $border-color;
            overflow-y: auto;

            &.is-focused {
                transition: box-shadow 0.15s ease-in-out;
                color: $input-focus-color;
                border-color: $input-focus-border-color;
                outline: 0;
                box-shadow: $input-focus-box-shadow;
            }
        }
    }
}