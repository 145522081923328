.post-share {
    &-modal {
        @include media-breakpoint-down(sm) {
            & .custom-modal-container {
                height: auto;
                box-shadow: $box-shadow-lg;
                position: absolute;
                bottom: 0;
                border: $border-color solid $border-width;
                border-top-left-radius: $border-radius-lg * 2;
                border-top-right-radius: $border-radius-lg * 2;
            }
        }
    }
    &-footer {
        position: relative;
    }
    &-label {
        width: 100%;
        margin-bottom: 0;
    }
    &-btn {
        display: inline-block;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    &-social-name {
        color: $text-muted;
        font-size: $font-size-sm;
    }
    &-social-btn {
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto map-get($spacers, 2) auto;
        color: #fff;

        &:hover {
            color: #fff;
        }

        &.facebook {
            background: map-get($theme-colors, "facebook");
            @include button-circle-variant($display3-size, map-get($theme-colors, "facebook"));
        }
        &.twitter {
            background: map-get($theme-colors, "twitter");
            @include button-circle-variant($display3-size, map-get($theme-colors, "twitter"));
        }
        &.instagram {
            background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);;
            @include button-circle-variant($display3-size, map-get($theme-colors, "instagram"));
        }
        &.linkedin {
            background: map-get($theme-colors, "linkedin");
            @include button-circle-variant($display3-size, map-get($theme-colors, "linkedin"));
        }
    }
}