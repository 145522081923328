.btn-outline-default {
    @include button-outline-variant($gray-600);
}
.btn-default {
    @include button-variant($light, $light);
    border: $border-width solid $border-color;
    box-shadow: $box-shadow-sm;
}

.btn-circle {
    @include button-circle-variant($btn-circle-size, darken($light, 5%));
    background-color: transparent;

    &-sm {
        @include button-circle-variant($btn-circle-size-sm, darken($light, 5%));
        background-color: transparent;
    }

    &-lg {
        @include button-circle-variant($btn-circle-size-lg, darken($light, 5%));
        background-color: transparent;
    }
}