.registration-option {
    align-items: center;
    background-color: $white;
    box-sizing: border-box;
    border-left: $border-width solid $border-color;
    border-right: $border-width solid $border-color;
    border-top: $border-width solid $border-color;
    cursor: pointer;
    display: flex;
    margin-bottom: 0;
    min-height: 75px;
    padding: $spacer;
    transition: box-shadow 0.15s ease-in-out;

    &:first-child {
        border-top: $border-width solid $border-color;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }
    &:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        border-bottom: $border-width solid $border-color;

        &.is-selected,
        &:hover {
            border-bottom: $border-width solid rgba($info, 0.6);
        }
    }

    &.is-selected,
    &:not(.is-disabled):hover {
        border-left: $border-width solid rgba($info, 0.6);
        border-right: $border-width solid rgba($info, 0.6);
        border-top: $border-width solid rgba($info, 0.6);

        & + .registration-option {
            border-top-color: rgba($info, 0.6);
        }
    }

    &.is-disabled {
        background-color: $light;
        cursor: default;
    }

    &:not(.is-disabled):hover {
        box-shadow: $box-shadow;
    }

    &:not(.is-disabled):hover .custom-control-input:not(:checked) ~ .custom-control-label::before {
        background-color: rgba($info, 0.25);
        border-color: rgba($info, 0.25);
        color: $white;
    }
}