.footer {
    &-logo {
        max-width: 80px;
        transition: opacity 0.15s ease-in-out;

        &:hover {
            opacity: 0.75;
        }
    }
    &-nav {
        width: 100%;
        column-count: 2;

        @include media-breakpoint-up(sm) {
            column-count: 3;
        }
    }
    &-newsletter-input {
        &-container {
            position: relative;
        }
        &-btn {
            height: $input-height;
            padding: $input-padding-y $input-padding-x;
            border: 0;
            background: transparent;
            outline: none;
            position: absolute;
            top: 0;
            right: 0;
            opacity: 0.5;
        }
    }
}