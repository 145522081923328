@use "sass:math";

.page-main {
    &-nav {
        display: flex;
        align-items: center;
        position: sticky;
        top: 0;
        z-index: $zindex-sticky;
        box-shadow: $box-shadow-sm;
        background-color: $white;
        height: $nav-height;

        &-search {
            width: 100%;

            .page-main-header & {
                width: auto;

                @include media-breakpoint-up(md) {
                    max-width: 300px;
                    width: 100%;
                }

                @include media-breakpoint-up(lg) {
                    max-width: 500px;
                }
            }

            @include media-breakpoint-down(sm) {
                padding-left: 0;
                padding-right: 0;
                flex-shrink: 1;

                &:not(.is-focused) {
                    margin-left: auto;
                }

                .page-main-header &.is-focused {
                    position: absolute;
                    top:0;
                    bottom:0;
                    right:0;
                    left:0;
                    height: $nav-height;
                    z-index: $zindex-popover;
                    height:100%;
                    width: 100%;

                    & .search-org-input-container {
                        border-radius: 0;
                        height: 100%;
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            &-btns, &-logo {
                flex: 1;
            }
        }


        &-logo {
            flex-shrink: 0;

            & a {
                display: block;
                height: 100%;
                padding-right: map-get($spacers, 2);
            }

            & img {
                height: $nav-height;
                max-height: 100%;
            }
        }

        &-btns {
            align-items: center;
            display: flex;
            justify-content: flex-end;
        }
    }
    &-header {
        position: sticky;
        top: 0;
        z-index: $zindex-sticky;
    }
}

.navbar {
    &-login-btn {
        background-color: transparent;
        border: 0;
        box-shadow: none;
        transition: color 0.15s ease-in-out;
        margin: 0 map-get($spacers, 2);
    }
    &-lang-btn {
        background-color: transparent;
        border: 0;
        box-shadow: none;
        margin: 0 map-get($spacers, 2);
        transition: color 0.15s ease-in-out;

        &:hover {
            color: $primary;
        }

        &:focus,
        &:active {
            box-shadow: none;
            outline: none;
        }
    }
    & .custom-modal {
        background-color: rgba($dark, .7);
        pointer-events: all;

        & .custom-modal-container {
            display: block;
            height: auto;
            position: absolute;
            bottom: 0;
            box-shadow: $box-shadow;
        }
    }
    &-profile {
        flex-shrink: 0;
        position: relative;

        &-dropdown {
            position: absolute;
            bottom: auto;
            right:0;
            box-shadow: $box-shadow-sm;
            border: $border-width solid $border-color;
            border-radius: $border-radius;
            background-color: $white;
            overflow: hidden;
        }
        &-preview {
            padding: map-get($spacers, 3);
            display: flex;
            align-items: center;
            border-bottom: $border-width solid $border-color;
        }
        &-btn {
            background-color: transparent;
            border: 0;
            box-shadow: none;

            & .navbar-img-pill {
                transition: box-shadow 0.25s ease-in-out;
            }

            &:hover .navbar-img-pill {
                box-shadow: 0 0 0 5px darken($light, 3%);
            }
        }
        &-list-item {
            padding: map-get($spacers, 3);
            display: block;
            transition: background-color 0.1s ease-in-out;
            color: $dark;

            &:hover {
                text-decoration: none;
                color: $dark;
                background-color: darken($light, 5%);
            }
        }
    }
}

// Burger Menu Icon
.custom-icon-burger {
    display: block;
    width: 100%;
    height: $burger-btn-lines-height;
    background-color: $text-muted;
    transition: background-color 0.15s ease-in-out;
    border-radius: $burger-btn-size;

    .is-open & {
        background-color: rgba(0,0,0,0);

        &::before {
            transform: rotate(45deg) translate(0);
        }
        &::after {
            transform: translate(0, -100%) rotate(-45deg);
        }
    }

    &-btn {
        flex-shrink: 0;
        width: math.div($burger-btn-size, 1.25);
        height: $burger-btn-size;
        display: block;
        color: $white;
        background-color: transparent;
        border: 0;
        padding: map-get($spacers, 2);
        margin-right: map-get($spacers, 2);

        &:focus,
        &:active,
        &:hover {
            outline: none;
            box-shadow: none;
            background-color: transparent;
            border: 0;
        }
    }
    &::before,
    &::after {
        content: '';
        border-radius: $burger-btn-size;
        display: block;
        width: 100%;
        height: $burger-btn-lines-height;
        background-color: $text-muted;
        transition: transform 0.15s ease-in-out;
    }
    &::before {
        transform: translate(0, -300%);
    }
    &::after {
        transform: translate(0, 200%);
    }
}

/* TABLET & DESKTOP */
@include media-breakpoint-up(lg) {
    .custom-icon-burger-btn {
        display: none;
    }
}

/* MOBILE ONLY*/
@include media-breakpoint-down(lg) {
    .navbar-burger-nav {
        width: 80%;
        height: 100%;
        background-color: $white;
        margin-left: auto;
        padding: $spacer;
        box-shadow: $box-shadow-sm;
        transform: translateX(100%);
        transition: transform 0.30s ease-in-out;
        border-left: $border-color solid $border-width;
        position: absolute;
        right: 0;

        .is-open & {
            transform: translateX(0);
        }

        & section {
            margin-bottom: map-get($spacers, 2);
        }

        &-container {
            box-sizing: border-box;
            left: 0;

            min-height: 100vh; // fallback
            min-height: calc(100vh - #{$nav-height});

            overflow: hidden;
            pointer-events: none;
            position:absolute;
            right: 0;
            top: 100%;
            transition: background-color 0.30s ease-in-out;
            z-index: $zindex-modal-backdrop;

            &.is-open {
                pointer-events: auto;
                background-color: rgba($dark, .7);
            }

        }
    }
}