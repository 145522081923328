.registration-form {
    &-item {
        width: 100%;
        height: 100%;
        background-color:$light;
        border-radius: $border-radius;
        border: 1px solid  $border-color;
        overflow: hidden;
    }
    &-item-img {
        max-width: 100%;
        max-height: 100%;
    }
}

.store-item-info-cell {
    width: 75%;

    @include media-breakpoint-down(md) {
        width: 50%;
    }
}