.waiver-helper {
    display: flex;
    align-items: center;
    background-color: white;
    padding: map-get($spacers, 1);
    margin-bottom: $spacer;
    border-radius: $card-border-radius;
    border: $border-width solid $border-color;

    &-icon {
        color: $info;
        margin: 0 map-get($spacers, 2);
    }
}