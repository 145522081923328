.page {
    &-aside{
        &-nav {
            &-item {
                margin-bottom: map-get($spacers, 1);
            }

            &-link {
                padding: map-get($spacers, 1) map-get($spacers, 2);
                display: block;
                color: darken(desaturate($primary, 70%), 30%);

                &:focus {
                    box-shadow: none;
                }

                &:hover {
                    text-decoration: none;
                    color: $primary;
                }

                &.active {
                    background-color: $light;
                    color: $primary;
                    border-radius: $border-radius;
                }

                &.is-disabled {
                    opacity: 0.5;

                    &:hover {
                        color: darken(desaturate($primary, 70%), 30%);
                    }
                }
            }
        }
    }
}