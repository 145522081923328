.teams-list {
    &-select {
        &-year {
            max-width: 33%;
        }
    }
}
.team-item {
    background-color: $white;
    border: $border-width solid $border-color;
    border-radius: $border-radius-lg;
    padding: map-get($spacers, 2);
    position: relative;
    box-shadow: $box-shadow-sm;

    & &-favorite {
        padding: map-get($spacers, 2);
        box-sizing: content-box;
        position: absolute;
        top: 0;
        right: 0;
    }
    &-info {
        padding-left: $spacer;
    }
    &-nav-list {
        font-size: 0.9rem;
    }
}