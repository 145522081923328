@use "sass:math";

.header {
    &-banner {
        height: 33vh;
        overflow: hidden;
        position: relative;

        &.is-placeholder {
            // header is smaller when it is a placeholder
            max-height: 150px;
            height: auto;
        }

        &-btn {
            color: $white;
            background-color: rgba(0,0,0,0.5);
            border: 0;
            position: absolute;
            bottom: 0;
            right: 0;
            margin-right: $spacer;
            margin-bottom: $spacer;
        }
    }
    &-organisation-logo {
        height: $img-pill-size-xs;
        width: $img-pill-size-xs;
        position: absolute;
        bottom: 0;
        left: 0;
        box-shadow: $box-shadow-sm;

        // temp fix for fake crop
        transform: scale(0.75);
        bottom: - map-get($spacers, 2);
        left: - map-get($spacers, 2);
    }
    &-association {
        display: flex;
        align-items: center;

        &-logo {
            &-container {
                box-shadow: $box-shadow-sm;
                height: $img-pill-size-xl;
                width: $img-pill-size-xl;
                // height: $img-pill-size;
                // width: $img-pill-size;
                position: relative;

                // temp fix for fake crop
                transform: scale(0.75);
                margin-top: - map-get($spacers, 3);
                margin-bottom: - map-get($spacers, 3);
                margin-left: - map-get($spacers, 3);
            }
        }
    }
}

/* DESKTOP ONLY*/
@include media-breakpoint-up(sm) {
    .header {
        &-association {
            &-logo {
                &-container {
                    margin-top: math.div(-$img-pill-size-xl, 2);
                    margin-right: $spacer;
                    // height: $img-pill-size-xl;
                    // width: $img-pill-size-xl;

                    // temp fix for fake crop
                    transform: scale(1);
                    margin-bottom: 0;
                    margin-left: 0;
                }
            }
        }
        &-organisation-logo {
            bottom: -5px;
            left: -5px;

            // temp fix for fake crop
            transform: scale(1);
        }
    }
}
