.roster {
    &-list {
        padding: 0;
    }
    &-item {
        list-style-type: none;
        margin-bottom: map-get($spacers, 2);

        &-inner {
            display: flex;
            align-items: center;
        }

        &-nb {
            font-size: 1.75rem;
            margin-left: $spacer;
            margin-right: $spacer;
            color: $text-muted;
            font-weight: 200;
        }

        &-name {
            font-size: 1rem;
            margin-bottom: 0;
        }

        &-specs {
            color: $text-muted;
            font-size: 0.85rem;
        }
    }
}