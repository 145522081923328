.select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: $input-padding-x * 1.5 + $input-font-size;

    &::-ms-expand {
        display: none;
    }
    &-label {
        width: 100%;
        font-size: $input-font-size-sm;
        position: relative;
        color: $text-muted;
    }
    &-arrow {
        @include font-size($input-font-size);
        position: absolute;
        right: 0;
        bottom: 0;
        padding-right: $input-padding-x;
        padding-bottom: $input-padding-y;
        padding-top: $input-padding-y;
        pointer-events: none;

        &::before {
            display: inline-block;
            transform: rotate(0deg);
            transform-origin: center;
            transition: transform 0.15s ease-in-out;

            .select-open & {
                transform: rotate(180deg);
            }
        }


    }
}