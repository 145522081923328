// @include media-breakpoint-up(sm) {
//     $nav-height: 2rem;
// }

.search-org {
    &-header-btns {
        width: 50%;
    }
    &-input {
        border: 0;
        box-shadow: none;
        margin-left: auto;
        outline: none;
        padding: 0;
        flex-grow: 1;
        flex-shrink: 1;
        background-color: transparent;
        width: 0;

        /* clears the ‘X’ from Internet Explorer */
        &::-ms-clear {
            display: none;
            width : 0;
            height: 0;
        }
        &::-ms-reveal {
            display: none;
            width : 0;
            height: 0;
        }
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }

        &-btn {
            color: $input-color;
            border: 0;
            font-size: $nav-search-btn-font-size;
            background: transparent;
        }

        &-container {
            align-items: center;
            display: flex;
            width: 100%;
            background-color: $light;
            border-radius: $border-radius-lg * 4;
            border: $border-width solid $border-color;
            padding: map-get($spacers, 1);
            position: relative;

            @include media-breakpoint-down(sm) {
                .page-main-header & {
                    border: none;
                    background-color: transparent;
                }
            }

            .is-focused & {
                box-shadow: $box-shadow-sm;
                background-color: $white;
            }

            &-inner {
                align-items: center;
                display: flex;
                width: 100%;
            }

            @include media-breakpoint-up(sm) {
                transition: height 0.25s ease-in-out, width 0.25s ease-in-out;
            }
        }
    }
    &-result,
    &-no-result {
        padding-left: map-get($spacers, 3);
        padding-right: map-get($spacers, 3);
        padding-top: map-get($spacers, 2);
        padding-bottom: map-get($spacers, 2);
    }
    &-result {
        display: flex;
        align-items: center;
        position: relative;
        transition: background-color 0.15s ease-in-out;

        &:hover {
            background-color: darken($light, 3%);
        }
    }
    &-results-container {
        background-color: $white;
        border: $border-color solid $border-width;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        left: 0;
        overflow-y: auto;
        padding-top: map-get($spacers, 3);
        padding-bottom: map-get($spacers, 3);
        position: absolute;
        top: 100%;
        transition: opacity 0.15s ease-in-out;
        width: 100%;
        max-height: 80vh;
        z-index: $zindex-dropdown;

        @include media-breakpoint-up(sm) {
            min-width: 100%;
            max-height: 50vh;
            width: auto;
        }

        &.is-open {
            box-sizing: border-box;
            padding-left: map-get($spacers, 3);
            padding-right: map-get($spacers, 3);
            width: 100%;

            @include media-breakpoint-down(sm) {
                position: absolute;
                left: 0;
                right: 0;
            }

            @include media-breakpoint-up(sm) {
                height: auto;
                padding-right: 0;
                padding-left: 0;
            }

            & .search-input {
                padding-left: map-get($spacers, 2);
                opacity: 1;
            }
        }

        &.is-shown {
            opacity: 1;
        }

        &.is-hidden {
            opacity: 0;
            pointer-events: none;

            & .stretched-link {
                display: none;
            }
        }
    }
}