.table {
    margin-bottom: 0;

    & .cell-sm {
        width: $btn-circle-size;
        box-sizing: content-box;
    }

    &-fixed {
        table-layout: fixed;
    }

    &-container {
        overflow-y: hidden;
        overflow-x: auto;
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        box-shadow: $box-shadow-sm;
    }
    & thead th {
        border-bottom: $border-width solid $border-color;
        color: $text-muted;
        font-size: $input-font-size-sm;
    }

    & td {
        vertical-align: middle;
    }

    & tbody tr {
        border-top: $border-width solid $border-color;
    }

    &-expandable {
        tbody tr:nth-child(4n),
        tbody tr:nth-child(4n-1) {
            background: $table-accent-bg;
        }

        tbody tr:nth-child(4n-2),
        tbody tr:nth-child(4n-3) {
            background: $table-bg;
        }

    }

    &-row {
        &-expandable {
            & td {
                padding: 0;
            }
            & .td-inner-content {
                padding: $table-cell-padding;
            }
        }
    }
}