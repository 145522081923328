.home {
    &-hero {
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        min-height: 75vh;

        &-h1 {
            text-shadow: 0 0 10px $dark;
        }

        &-content {
            color: $light;
            z-index: 1;
            position: relative;
        }

        &::after {
            content: '';
            background: linear-gradient(to top, rgba(0,0,0,0.85), rgba(0,0,0,0.5));
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
    &-layer {
        &-assist {
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;

            & .text-shadow {
                text-shadow: 0 0 10px darken($primary, 20%);
            }
        }
        &-card {
            box-shadow: $box-shadow-sm;
            transition: transform 0.30s ease-in-out, box-shadow 0.30s ease-in-out;

            &-container:hover & {
                transform: translateY(-10px);
                box-shadow: $box-shadow;
            }

            &-overlay {
                top: auto;
                right: auto;
                left: 0;
                bottom: 0;
            }
        }
    }
}
