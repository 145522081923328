.max-vh-50 {
    max-height: 50vh !important;
}

.w {
    @each $key, $value in $grid-breakpoints {
        &-#{$key} {
            @each $size, $length in $sizes {
                &-#{$size} {
                    @include media-breakpoint-up(#{$key}) {
                        width: $length !important;
                    }
                }
            }
        }
    }
}

.min-w {
    @each $size, $length in $sizes {
        &-#{$size} {
            min-width: $length !important;
        }
        @each $key, $value in $grid-breakpoints {
            &-#{key}-#{$size} {
                @include media-breakpoint-up(#{$key}) {
                    min-width: $length !important;
                }
            }
        }
    }
}

.max-w {
    @each $size, $length in $sizes {
        &-#{$size} {
            max-width: $length !important;
        }
        @each $key, $value in $grid-breakpoints {
            &-#{$key}-#{$size} {
                @include media-breakpoint-up(#{$key}) {
                    max-width: $length !important;
                }
            }
        }
    }
}