@use "sass:math";

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');

$primary: #FF1744;
$dark: #3E3E3E;
$info: #4595F7;
$light: #F4F6F9;
$white: #fff;
$enable-validation-icons: false;

$clinic-colors: (
    1: #3F96FB,
    2: #EA4335,
    3: #33A652,
    4: #9B55B7,
    5: #E0732E,
    6: #d84199,
);

$theme-colors: (
  "facebook": #3b5998,
  "instagram": #e6683c,
  "twitter": #00acee,
  "linkedin": #0e76a8,
);

$nav-height: 4rem;
$nav-search-btn-font-size: 1.25rem;

$container-lg-width: 1750px;
$container-sm-width: 768px;

$font-size-base: 1rem;
$font-family-sans-serif: 'Rubik', sans-serif;
$font-weight-bold: 500;
$container-sm-width: 768px;

$close-color: inherit;

$min-helper-font-size: 10;
$max-helper-font-size: 24;

$transition-duration: .35s; // collapse's default duration
$burger-btn-size : 40px;
$burger-btn-lines-height: 2px;
$filter-dot-size: 10px;

$h1-font-size: $font-size-base * 1.75;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base * 1.1;
$h5-font-size: $font-size-base * 0.85;
$h6-font-size: $font-size-base * 0.75;

$display3-size: $font-size-base * 3;
$display4-size: $font-size-base * 2;

$headings-font-weight: 300;

$input-switch-toggler-size: 0.75em !default;
$input-switch-width: 2em !default;
$input-switch-border-width: 3px !default;
$input-switch-color-off: gray !default;

$btn-circle-size: 40px;
$btn-circle-size-sm: math.div($btn-circle-size, 1.5);
$btn-circle-size-lg: $btn-circle-size * 2;

$img-pill-size: 75px;
$img-pill-size-xs: math.div($img-pill-size, 2);
$img-pill-size-sm: math.div($img-pill-size, 1.5);
$img-pill-size-lg: $img-pill-size * 1.25;
$img-pill-size-xl: $img-pill-size * 1.5;

$google-map-height: 200px;
$google-map-sm-height: $google-map-height;
$google-map-md-height: $google-map-height * 1.5;
$google-map-lg-height: $google-map-height * 2;

$table-border-width: 0;
$table-bg : $white;
$table-accent-bg: $light;
$table-th-font-weight: 400;
$table-striped-order: even;

$divider-height: 2px;
$divider-width: 100px;
$divider-width-sm: math.div($divider-width, 2);
$divider-width-lg: $divider-width * 1.5;

$component-active-bg: $info; // we want at least all form's color to be $info
$input-focus-border-color: lighten($info, 10%);
