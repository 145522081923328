.custom-filter {
    &-input {
        flex-basis: 50%;

        &-container {
            display: flex;
            flex-wrap: wrap;
        }

        @include media-breakpoint-up(md) {
            flex: 1 1 auto;

            &-container {
                flex-wrap: nowrap;
            }
        }
    }
}