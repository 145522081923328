.divider {
    height: $divider-height;
    background-color: $primary;
    width: $divider-width;

    &-sm {
        width: $divider-width-sm;
    }
    &-md {
        width: $divider-width;
    }
    &-lg {
        width: $divider-width-lg;
    }
}