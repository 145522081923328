.skeleton {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loading;
    animation-timing-function: linear;
    background: darken($light, 3%);
    background: linear-gradient(to right, darken($light, 3%) 8%, $light 38%, darken($light, 3%) 54%);
    background-size: 1000px 640px;
    border-radius: $border-radius;
    margin: map-get($spacers, 1) 0;

    &.is-circle {
        border-radius: 1000px;
        flex-shrink: 0;
    }
}

@keyframes loading {
    0% { background-position: -468px 0 }
    100% { background-position: 468px 0 }
}