.publication {
    background-color: $white;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    margin-bottom: map-get($spacers, 3);
    padding: map-get($spacers, 2);
    position: relative;

    &.is-highlighted {
        padding: $spacer;
    }

    &-external {
        background-color: $light;

        & .publication-content {
            padding-left: map-get($spacers, 2);
            padding-bottom: map-get($spacers, 2);
            padding-right: map-get($spacers, 2);
        }
    }
    &-header {
        margin-bottom: map-get($spacers, 2);
    }
    &-author {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0;
    }
    &-title {
        text-transform: capitalize;
        font-size: 1.2rem;
    }
    &-p {
        font-size: 0.9rem;
    }
    &-btn {
        color: $text-muted;
        background-color: transparent;
        border: 0;
        display: flex;
        align-items: center;

        &:hover {
            background-color: darken($light, 5%);
        }
    }
    &-options-container,
    &-external-btn {
        align-self: flex-start;
        margin-left: auto;
    }

    &-options {
        & .publication-btn {
            width: 100%;
            border-radius: 0;
            padding: map-get($spacers, 2) map-get($spacers, 3);
        }
    }
    &-external-link {
        opacity: 0.75;
        text-transform: uppercase;
        font-size: 0.75rem;
    }
    &-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;

        &-container {
            border-radius: $border-radius-lg;
            overflow: hidden;
            margin-bottom: map-get($spacers, 3);

            .is-highlighted & {
                // height: 300px;
            }
        }
    }
}

/* MEDIA QUERY TABLET DESKTOP */
@include media-breakpoint-up(sm) {
    .publication{
        &-options-container {
            text-align: left;
        }
    }
}