.schedule-weekly {
    &-calendar {
        display: flex;
        overflow-x: auto;
    }
    &-item {
        flex-basis: 0;
        flex-shrink: 0;
        flex-grow: 1;
        transition: flex-basis 0.15s ease-in-out;

        &, &:focus {
            border-top: 0;
            border-bottom: 0;
            border-right: 0;
            outline: none;
        }
    }
    &-days {
        text-align: center;
        flex: 1 1 0px;
        background-color: $white;
        border-bottom: $border-width solid $border-color;

        &-container {
            display: flex;
            flex-basis: 100%;
            border-radius: $border-radius;
            overflow: hidden;
            background-color: $light;
            border-radius: $border-radius;
            border: $border-width solid $border-color;
        }
    }
    &-day-nb {
        font-size: $h5-font-size;
        padding-bottom: map-get($spacers, 2);
        display: block;

        .is-today & {
            color: $primary;
        }
    }
    &-weekday {
        padding-top: map-get($spacers, 2);
        font-size: $font-size-sm;
        color: $text-muted;
        font-weight: 400;
    }
    &-events-container {
        padding: map-get($spacers, 3) map-get($spacers, 2);
    }
}

/* MEDIA QUERY TABLET DESKTOP */
@include media-breakpoint-up(lg) {
    .schedule-weekly {
        &-calendar {
            min-height: 33vh;
        }
    }
}