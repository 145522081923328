@mixin img-pill-style($size) {
    background-color: $white;
    border: $border-width solid $border-color;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    @include circle($size);
}

.img-pill {
    &.is-loading {
        animation-name: imgLoadEffect;
        animation-duration: 4s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        background: linear-gradient(to right, darken($light, 5%) 5%, $light 20%, darken($light, 5%) 35%);
    }
    &-container {
        @include img-pill-style($img-pill-size);

        &-xs {
            @include img-pill-style($img-pill-size-xs);
        }
        &-sm {
            @include img-pill-style($img-pill-size-sm);
        }
        &-lg {
            @include img-pill-style($img-pill-size-lg);
        }
        &-xl {
            @include img-pill-style($img-pill-size-xl);
        }
    }
    &-inner {
        max-width: 85%;
        max-height: 85%;
    }
    &-inner-100 {
        max-width: 100%;
        max-height: 100%;

    }
}

@keyframes imgLoadEffect {
    0% {
        background-position: 0px 0;
      }
      100% {
        background-position: 10em 0;
      }
}