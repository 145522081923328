@use "sass:math";

body {
    & * {
        &::selection {
            background-color:$primary;
            color: $white;
        }
        &::-moz-selection {
            background-color: $primary;
            color: $white;
        }
    }
}

img {
    max-width: 100%;
}

p,
address,
time,
small {
    color: $dark;
}

#root {
    min-height: 100vh;
    // overflow removed to enable sticky behaviour. if something overflow, fix it locally
    // overflow-x: hidden;
}

// Google places
.pac-container {
    z-index: $zindex-tooltip;
}

.section {
    &-container {
        border-radius: $border-radius-lg;
        border: $border-width solid $border-color;
        overflow: hidden;
        box-shadow: $box-shadow-sm;
        background-color: $white;
        margin-top: map-get($spacers, 3);
        margin-bottom: map-get($spacers, 3);
        padding: $spacer;

        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
            margin-top: map-get($spacers, 2);
            padding: math.div($grid-gutter-width, 2);
            margin-left: -(math.div($grid-gutter-width, 2));
            margin-right: -(math.div($grid-gutter-width, 2));
        }
    }
    &-header {
        &-select {
            &-year {
                flex-basis: 25%;
                margin-right: map-get($spacers, 1);
                margin-left: map-get($spacers, 2);

                &-lg {
                    flex-basis: 33%;
                    margin-right: 0;
                    margin-left: map-get($spacers, 2);
                }
            }
            &-season {
                flex-basis: 25%;
            }
        }
    }
    &-main-title {
        border-bottom: $border-width solid $border-color;
        padding-bottom: map-get($spacers, 2);
        margin-bottom: map-get($spacers, 3);
        flex: 1 1 auto;
    }

    &-inner-full-width {
        margin-left: -$spacer;
        margin-right: -$spacer;
    }
}

@include media-breakpoint-up(sm) {
    .section-container {
        border-radius: $border-radius-lg;
    }
}

//Move this in right file
.btn-circle i {
    display: inline-block;
    transition: transform 0.15s ease-in-out;
    transform: rotate(0deg);
}

.btn-circle.rotated i {
    transform: rotate(-180deg);
}

.modal:not(.show) .close {
    pointer-events: none;
}

.pointer-events-auto {
    pointer-events: auto;
}

.pointer-events-all {
    pointer-events: all;
}