.contacts {
    &-google-map {
        border: 0;
        height: 100%;
        width: 100%;
        animation-name: loadEffect;
        animation-duration: 3s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        background: linear-gradient(to right, $light 8%, $white 18%, $light 33%);

        &-container {
            height: $google-map-height;
            width: 100%;
        }
    }

    &-single-contact {
        list-style-type: none;

        &-phone {
            font-size: 0.75rem;
        }

    }
}

@keyframes loadEffect {
    0% {
        background-position: 0px 0;
      }
      100% {
        background-position: 100em 0;
      }
}

/* TABLET */
@include media-breakpoint-up(md) {
    .contacts-google-map-container {
        height: $google-map-md-height;
    }
}

/* DESKTOP */
@include media-breakpoint-up(xl) {
    .contacts-google-map-container {
        height: $google-map-lg-height;
    }
}