.event-item {
    margin-bottom: $spacer;

    &-weekly {
        font-size: $font-size-sm;
        margin-bottom: map-get($spacers, 2);

        .event-item-inner {
            border-left: 0.2rem solid;
        }

        .event-item-location .mdi,
        .event-item-type,
        .event-item-time-end,
        .event-item-time-separator {
            display: none;
        }
        .event-item-time {
            font-size: 12px;
        }
        .event-item-location {
            font-size: 12px;
            color: $text-muted;
            margin-top: map-get($spacers, 2);
        }
    }
    &-type {
        color: $text-muted;
        font-size: $font-size-sm;
    }
    &-inner {
        cursor: pointer;
        box-shadow: $box-shadow-sm;
        border-radius: $border-radius;
        border: $border-width solid $border-color;
        padding-top: $spacer;
        padding-bottom: $spacer;
        transition: box-shadow 0.15s ease-in-out;
        background-color: $white;

        &:hover {
            box-shadow: $box-shadow;
        }

        .event-item-weekly & {
            padding-top: map-get($spacers, 2);
            padding-bottom: map-get($spacers, 2);
        }
    }
    &-info {
        display: flex;
        justify-content: space-between;

        .event-item-weekly & {
            flex-direction: column-reverse;
            margin-bottom: map-get($spacers, 2);
        }
    }

    &-info,
    &-location {
        padding-left: $spacer;
        padding-right: $spacer;

        .event-item-weekly & {
            padding-left: map-get($spacers, 2);
            padding-right: map-get($spacers, 2);
        }
    }

    &-name {
        border-left: 0.2rem solid;
        padding-left: map-get($spacers, 3) - 0.2rem;
        font-weight: 500;
        font-size: $h4-font-size;
        margin-bottom: -5px;
        margin-top: -5px;
        padding-bottom: 5px;
        padding-top: 5px;

        .event-item-weekly & {
            border: 0;
            padding-left: map-get($spacers, 2);
            padding-right: map-get($spacers, 2);
        }
    }
}