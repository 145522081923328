.registration-card {
    background-color: $card-bg;
    padding: $card-spacer-x;
    border: $card-border-width solid $card-border-color;
    border-radius: $card-border-radius;

    display: flex;
    align-items: center;
    margin-bottom: map-get($spacers, 4);
    transition: $transition-base;

    &:hover { // registration-card:hover
        border-color: rgba($info, 0.6);
        cursor: pointer;
    }

    &.is-selected {
        border-color: $info;
        box-shadow: $box-shadow-sm;
    }



    &-icon {
        height: 2rem;
        line-height: 2rem;
        width: 2rem;
        flex: 0 0 2rem;
        text-align: center;
        border-radius: 50%;
        margin-left: auto;
        color: $white;
        background-color: $info;

        opacity: 0;
        transition: $transition-base;

        &.is-visible { // used when the icon must always be visible, ex: cart icon
            opacity: 1 !important;
        }
    }

    &:hover &-icon {
        opacity: 0.6
    }

    &.is-selected &-icon {
        opacity: 1;
    }

    // Alert overwrites
    &.is-alert {
        &:hover {
            border-color: rgba($warning, 0.6);
        }

        &.is-selected {
            border-color: $warning;
        }

        .registration-card-icon {
            background-color: $warning;
        }

    }
}

.registration-btn {
    display: block;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transition: $transition-base
}

@include media-breakpoint-up(md) {
    .registration {
        &-container {
            max-width: 500px;
            margin: 0 auto;
        }

        &-modal .modal-dialog {
            max-width: 700px;
        }
    }
}
