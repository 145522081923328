.accordion {
    &-container {
        margin-bottom: map-get($spacers, 2);
        background-color: $white;
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        box-shadow: $box-shadow-sm;
        transition: box-shadow 0.15s ease-in-out;

        &:hover {
            box-shadow: $box-shadow;
        }
    }
    &-btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-weight: 500;

        &:focus {
            box-shadow: none;
        }

        & .mdi-plus {
            display: inline-block;
            transition: transform 0.15s ease-in-out;
        }

        &.is-open .mdi-plus {
            transform: rotate(45deg);
        }
    }
}